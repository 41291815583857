import {MRT_ColumnDef} from "material-react-table";
import React, {useEffect, useState} from "react";
import Card from "react-bootstrap/Card";
import {EventsType} from "../events-types";
import {Box, Button, IconButton} from "@mui/material";
import Events from '../events';
import {Strain} from "../components/tabs/plants_varieties/types";
import {CannabisCompounds, Compound, Pubchem} from "../components/tabs/compounds/types";
import {Metabolite} from "../components/tabs/pharmacology/types";
import {ClinicalTrial, PBClinicalTrials, TherapeuticIndicator} from "../components/tabs/./disorders_diseases/types";

type CellRenderer = (columnName: string, cell: any, row: any) => React.ReactNode;

export type CellEventMapping = {
    [key: string]: EventsType;
};

const columns_as_integers = ['PUBCHEM_CID', 'ISOLATED', 'REF_ARTICLE', 'REFERENCE_ID', 'YEAR',
    'NUMBER_OF_PATIENTS', 'MALE_FEMALE', 'ANONYMOUS_PRODUCER', 'STRAIN_ID',
    'TIMEPOINT_WEEKS', 'N_BIO_REPLICATES', 'N_TECHNICAL_REPLICATES'];

const defaultCellRenderer = (columnName: string, cell: { getValue: () => any; id: React.Key | null | undefined; }, row: any ) => {
    if (columnName === 'NCT_ID' || columnName === 'ctid') {
        const row = cell.getValue();
        return (
            <React.Fragment key={cell.id}>
                <Card.Link target="_blank" href={`https://clinicaltrials.gov/ct2/show/${row}`}>
                    <>{row}</>
                </Card.Link>
            </React.Fragment>
        );
    } else if (columnName === 'date') {
        const value = cell.getValue();
        const _date = new Date(value);
        return (
            <>
                {new Intl.DateTimeFormat(undefined, {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                }).format(new Date(_date))}
            </>
        );
    } else if (columnName.endsWith('_DATE')) {
        const value = cell.getValue();
        return (
            <>
                {new Intl.DateTimeFormat(undefined, {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                }).format(value)}
            </>
        );
    } else if (columnName === 'diff_before' || columnName === 'diff_after') {
        const value = cell.getValue() as any;
        return (
            <>
                {Object.keys(value).map((key) => (
                    <div key={key}>
                        <b>{key}</b>: {value[key]}
                    </div>
                ))}
            </>
        );
    } else {
        return <>{cell.getValue()}</>;
    }
};

export const createCellsWithEvents = (eventMapping: CellEventMapping): CellRenderer => {
    return (columnName, cell, row) => {
        const cellValue = cell.getValue();
        if (Object.keys(eventMapping).includes(columnName)) {
            return (
                <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                    <Button
                        size="small"
                        color="primary"
                        onClick={() => {
                            Events.trigger(eventMapping[columnName], row.original[columnName]);
                        }}
                    >
                        {cell.getValue()}
                    </Button>
                </Box>
            );
        } else {
            return defaultCellRenderer(columnName, cell, row);
        }
    };
};


export const getColumns = (dataObject: Record<string, any>, digits = 2, cellRenderer: CellRenderer = defaultCellRenderer): MRT_ColumnDef<any>[] => {
    const propertyNames = Object.keys(dataObject);

    return propertyNames.map((columnName) => ({
        accessorKey: columnName,
        header: columnName,
        muiTableHeadCellProps: { sx: { color: 'green' } },
        columnFilterModeOptions: ['between', 'lessThan', 'greaterThan'],
        Cell: ({ cell, row }) => cellRenderer(columnName, cell, row),
        accessorFn: (row: { [x: string]: any; }) => {
            if (Array.isArray(row[columnName])) {
                return row[columnName].join(' ~~~~ ');
            } else {
                return (typeof row[columnName] === 'number' && !columns_as_integers.includes(columnName))
                    ? row[columnName].toFixed(digits)
                    : row[columnName];
            }
        },
    }));
};


export const getInitialColumnState = (allColumns: string[], viewVolumns: string[]) => {
    const initialState: Record<string, any> = {};

    allColumns.forEach((columnName) => {
        initialState[columnName] = false;
    });

    viewVolumns.forEach((columnName) => {
        initialState[columnName] = true;
    });

    return initialState;
};

const listOfHeadersAndObjectTypes = [
    { HEADER: 'Compounds', TYPE: Compound },
    { HEADER: 'Cannabis Compounds', TYPE: CannabisCompounds },
    { HEADER: 'Metabolites', TYPE: Metabolite },
    { HEADER: 'Varieties', TYPE: Strain },
    { HEADER: 'Therapeutic Indications', TYPE: TherapeuticIndicator },
    { HEADER: 'PK', TYPE: 'PK' },
    { HEADER: 'PUBCHEM - Clinical Trials', TYPE: PBClinicalTrials },
    { HEADER: 'Clinical Trials GOV' , TYPE: ClinicalTrial },
    { HEADER: 'PUBCHEM - Properties', TYPE: Pubchem},
]


export const getHeadersFromHeaderName = (headerName: string) => {
    const foundObject = listOfHeadersAndObjectTypes.find((obj) => obj.HEADER === headerName);
    if (foundObject) {
        return Object.keys(foundObject.TYPE);
    }
    else return []
}

export const RenderDataOnATable: React.FC<{ data: any[] }> = ({ data }) => {
    const [key, setKey] = useState(0);

    useEffect(() => {
        setKey((prevKey) => prevKey + 1);
    }, [data]);

    // Extract column names from the first item in the array
    const columnNames = data.length > 0 ? Object.keys(data[0]) : [];

    return (
        <div key={key} style={{ overflowX: 'auto', maxWidth: '100%' }}>
            <br />
            {(
                <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                    <tr style={{ backgroundColor: 'lightblue' }}>
                        {columnNames.map((columnName, index) => (
                            <th key={index}>{columnName}</th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {data.slice(0, 5).map((rowData, rowIndex) => (
                        <tr key={rowIndex} style={{ backgroundColor: rowIndex % 2 === 0 ? 'lightgrey' : 'white' }}>
                            {columnNames.map((columnName, colIndex) => (
                                <td key={colIndex}>{rowData[columnName]}</td>
                            ))}
                        </tr>
                    ))}
                    <tr>
                        <td colSpan={5} align={"center"}>
                            <br />
                            <b>. . . Total rows:</b> {data.length}
                        </td>
                    </tr>
                    </tbody>
                </table>
                )}
        </div>
    );
};


import React, {useEffect} from 'react';
import {
    REFERENCES,
    getObjectByField,
    save,
    deleteDoc,
    getObjectByDoc,
    getObjectsByRegEx,
    COMPOUND
} from '../../../api/Compounds';
import {iBaseCardWithComponentProps, iBaseInnerCardProps} from "../../types";
import {CardWithBaseComponent} from "../../common/BaseCard";
import Events from "../../../events";
import {EventsType} from "../../../events-types";
import {isFieldNamed, returnPubMedId } from "../../../utils/pair_entry";
import FormEditModal from "../../common/FormEditModal";
import AuditModal from "../../common/AuditModal";
import DisplayDeleteModal from "../../common/DeleteConfirmationModal";
import {isNotEmpty} from "../../../utils/string";
import {Reference} from "./types";


const _ReferenceCard: React.FC<iBaseInnerCardProps> = ({ setIsShow, setError, setData, data , isActive, searchBy, searchByDoc}) => {

    const UUID = '_ReferenceCard'

    // MODAL EDIT CALLBACK

    const saveData = async ( data: any) => {
        save(REFERENCES, data).then((res) => {
            setData(res);
            setIsShow(true);
        }).catch(setError);
    }

    const deleteData = async (data: any) => {
        deleteDoc(REFERENCES, data).then((res) => {
            setData({});
            setIsShow(false);
        }).catch(setError);
    }

    const undo = async (undoData:any) => {
        Object.assign(data, undoData);
        save(REFERENCES, data).then((res) => {
            setData(res);
            setIsShow(true);
        }).catch(setError);
    }

    const createEvent = (event: { detail: any }) => {
        if (isNotEmpty(event.detail)) {
            delete event.detail._id;
            saveData(event.detail);
        }
    };


    // SEARCH ----------------------

    const searchEvent = (event: { detail: string }) => {
        if (isActive){
            searchBy?.(getObjectsByRegEx, REFERENCES, 'REFERENCE_TITLE', event.detail);
        }

    }

    const searchByReferenceFieldEvent = (event: { detail: string }) => {
        if (isActive) {
            searchBy?.(getObjectByField, REFERENCES, 'REFERENCE_ID' , event.detail)
        }

    }

    const searchByCompoundEvent = (event: { detail: any }) => {
        if (isActive){
            searchByDoc?.(getObjectByDoc, REFERENCES, event.detail, true, 'REFERENCE_ID' );
        }

    }


    // ----- EVENTS

    useEffect(() => {
        Events.on(EventsType.CREATE, createEvent, UUID);
        Events.on(EventsType.SEARCH, searchEvent, UUID);
        Events.on(EventsType.SEARCH_BY_REFERENCE_FIELD, searchByReferenceFieldEvent, UUID);
        Events.on(EventsType.SEARCH_BY_REFERENCE, searchByCompoundEvent, UUID);
        return () => {
            Events.off(EventsType.CREATE, UUID);
            Events.off(EventsType.SEARCH, UUID);
            Events.off(EventsType.SEARCH_BY_REFERENCE_FIELD, UUID);
            Events.off(EventsType.SEARCH_BY_REFERENCE, UUID);
        };
    }, [isActive]);

    // RENDERS --------------

    function renderField(fieldName: string, digits: number = 2) {
        const _fieldValue = data[fieldName];

        if (_fieldValue) {

            if (isFieldNamed(fieldName, 'REFERENCE_PMID', data)) {
                return returnPubMedId(data);
            } else {
                return (
                    <>
                        <b>{fieldName.replace("REFERENCE_", " ")}</b> :{_fieldValue}
                        <br/>
                    </>
                );
            }
        }
        return null;
    }

    function renderFields() {
        const fieldNames = Object.keys(Reference);

        return (
            <>
                {fieldNames.map((fieldName) => (
                    <React.Fragment key={fieldName}>
                        {renderField(fieldName)}
                    </React.Fragment>
                ))}
            </>
        );
    }

    return(
        <p>
            {renderFields()}

            <FormEditModal
                save={saveData}
                data={data}
                schema={Reference}/>

            <AuditModal id={data._id} undo={undo}/>

            <DisplayDeleteModal deleteDoc={deleteData}
                                data={data}
                                schema={Reference}/>
        </p>
    );


};


const ReferenceCard: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({ header, width, eventGroupToTrigger }) => {
    return <CardWithBaseComponent cardComponent={_ReferenceCard} header={header} width={width} eventGroupToTrigger={eventGroupToTrigger}/>;
};

export default React.memo(ReferenceCard);

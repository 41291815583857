import React, {useEffect} from 'react';
import Events from '../../../events';
import { EventsType } from '../../../events-types';
import {
    getObjectByDoc,
    getStudiesRegEx,
    save,
    deleteDoc,
    STUDIES_CBD,
    SC_THERAPEUTIC_INDICATIONS
} from '../../../api/Studies';
import { iBaseCardWithComponentProps, iBaseInnerCardProps} from "../../types";
import {CardWithBaseComponent} from "../../common/BaseCard";
import {isNotEmpty, isNotUndefined} from "../../../utils/string"
import FormEditModal from "../../common/FormEditModal";
import {renderFields} from "../../../utils/pair_entry";
import AuditModal from "../../common/AuditModal";
import DisplayDeleteModal from "../../common/DeleteConfirmationModal";
import {useRoles} from "../../../App.Context";
import {TherapeuticIndicator} from "./types";

const _TherapeuticalIndicatorCard: React.FC<iBaseInnerCardProps> = ({ setIsShow, setError,
                                                                               setData, data , isActive,
                                                                               setTitle, searchByDoc }) => {
    const UUID = '_TherapeuticalIndicatorCard';
    const roles = useRoles();
    const hasReadOnlyRole = roles.includes("ReadOnly");

    // MODAL EDIT CALLBACK

    const saveData = async (data: any) => {
        save(STUDIES_CBD, data).then((res) => {
            setData(res);
        }).catch(setError);
    }


    const deleteData = async (data: any) => {
        deleteDoc(STUDIES_CBD, data).then((res) => {
            setData({});
            setIsShow(false);
        }).catch(setError);
    }

    const undo = async (undoData:any) => {
        Object.assign(data, undoData);
        save(STUDIES_CBD, data).then((res) => {
            setData(res);
        }).catch(setError);
    }

    const createEvent = (event: { detail: any }) => {
        if (isNotEmpty(event.detail)) {
            delete event.detail._id;
            saveData(event.detail);
        }
    };

    // SEARCH ----------------------

    function _search(text: string) {
        if (text.length > 0) {
            setTitle(text);
            getStudiesRegEx(STUDIES_CBD, SC_THERAPEUTIC_INDICATIONS,'PRODUCT,TITLE',text)
                .then(response => {
                    if (isNotUndefined(response) && response.length === 1){
                        setData(response[0]);
                        setIsShow(true)
                    }
                }).catch(error => {
                setError(error);
                setIsShow(true);
            });
        }
    }

    const searchEvent = (event: { detail: string }) => {
        if (isActive) {
            _search(event.detail);
        }
    }

    const searchByStudyTI =  (event: { detail: any }) => {
        if (isActive) {
            setTitle(event.detail.TITLE);
            setIsShow(false);
            setData(event.detail);
            setIsShow(true);
        }
    }

    const searchByCompoundEvent =  (event: { detail: any }) => {
        if (isActive) {
            searchByDoc?.(getObjectByDoc, STUDIES_CBD, event.detail, true, 'TITLE' );
        }
    }

    // ----- EVENTS

    useEffect(() => {
        Events.on(EventsType.CREATE, createEvent, UUID);
        Events.on(EventsType.SEARCH, searchEvent, UUID);
        Events.on(EventsType.SEARCH_BY_STUDY_TI, searchByStudyTI, UUID);
        Events.on(EventsType.SEARCH_BY_STUDY, searchByCompoundEvent, UUID);

        return () => {
            Events.off(EventsType.CREATE, UUID);
            Events.off(EventsType.SEARCH, UUID);
            Events.off(EventsType.SEARCH_BY_STUDY_TI, UUID);
            Events.off(EventsType.SEARCH_BY_STUDY, UUID);

        };
    }, [isActive]);

    // RENDERS --------------

    return(
        <p>
            {renderFields(TherapeuticIndicator, data)}
            { !hasReadOnlyRole && (
                <>

            <FormEditModal
                           save={saveData}
                           data={data}
                           schema={TherapeuticIndicator}/>

            <DisplayDeleteModal deleteDoc={deleteData}
                                data={data}
                                schema={TherapeuticIndicator}/>
                </>
                )}

            <AuditModal id={data._id} undo={undo}/>

        </p>
    );

};



const TherapeuticalIndicatorCard: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({ header, width, eventGroupToTrigger }) => {
    return <CardWithBaseComponent cardComponent={_TherapeuticalIndicatorCard} header={header} width={width} eventGroupToTrigger={eventGroupToTrigger}/>;
};

export default React.memo(TherapeuticalIndicatorCard);
